<template>
  <div>
<!--   <el-tooltip v-if="isButtonType" :content="`${$t('force_revalidation')}`" placement="top">-->
<!--      <p-button type="warning" link  v-on:click="forceRevalidation(scope.row)">-->
<!--        <i class="fa fa-upload" style="font-size: x-large;"></i>-->
<!--      </p-button>-->
<!--    </el-tooltip>-->
    <!-- Modal for force revalidation -->
    <modal id="showForceRevalidationModal" :show.sync="showForceRevalidationModal" headerClasses="justify-content-center" :required="true">
      <h4 slot="header" class="title title-up">
        {{ $t('forcerevalidation') }}
      </h4>

      <div class="row">
        <div class="col-6">
          <fg-input
            :label="`${$t('accountNumber')}`"
            readonly="true"
            v-model="account.accountNumber">
          </fg-input>
        </div>
        <div class="col-6">
          <fg-input
            :label="`${$t('brokerservers')}`"
            readonly="true"
            v-model="account.server">
          </fg-input>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <fg-input
            :label="`${$t('usertxt')}`"
            readonly="true"
            v-model="account.userid">
          </fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
        </div>
        <div class="col-8">
          <fg-input
            :label="`${$t('revalidationsymbol')}`"
            v-model="account.symbol">
          </fg-input>
        </div>
      </div>

      <template slot="footer">
        <div class="left-side">
          <p-button type="success" v-on:click="sendToRevalidate()" link>{{ $t('confirm') }}</p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="danger" v-on:click="closeForceRevalidationModal()" link>{{
              $t('cancel')
            }}
          </p-button>
        </div>
      </template>
    </modal>
    <!-- End Modal -->
  </div>
</template>
<script >

import Vue from "vue";
import Component from "vue-class-component";
import {Modal} from "@/components/UIComponents";
import {Tooltip} from "element-ui";
import {mapActions, mapState} from "vuex";

export default  {
  name: "force-revalidation",
  components: {
    Modal,
    [Tooltip.name]: Tooltip,
  },
  computed: {
    ...mapState('forceRevalidation', ['showForceRevalidationModal', 'account'])
  },
  props: {
    isButtonType: {
      default: false,
      description: 'Indicates if it must behave like a button or not'
    }
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions('forceRevalidation', ['openForceRevalidationModal', 'closeForceRevalidationModal']),
    // forceRevalidation(row) {
    //     this.account = row;
    //     this.account.present = `${row.account} (${row.type}) (${row.platform})`;
    //     if (row.platform == 'mt4') {
    //       this.account.symbol = 'EURUSD'
    //     }
    //     this.showForceRevalidationModal = true;
    //   },
    sendToRevalidate() {
      if (!this.account.symbol) {
        this.$toast.warning(this.$t('warninginformaccountsymbol'))
        return;
      }
      this.$addAccountToPriorityRev({
        account: this.account.accountNumber,
        password: this.account.password,
        server: this.account.server,
        type: this.account.type,
        symbol: this.account.symbol,
        platform: this.account.platform,
      }).then(this.addSuccessHandler, this.addErrorHandler);
    },
    addSuccessHandler(resp) {
      if (!resp.success) {
        this.addErrorHandler(resp)
        return;
      }
      this.$toast.success(this.$t('accountrevalidationpipelined'))
      this.closeForceRevalidationModal()
    },
    addErrorHandler(resp) {
      console.log(resp)
      this.$toast.warning(this.$t('failtopipelineaccountrevalidation'));
    }
  }
}
</script>
<style scoped>

</style>
