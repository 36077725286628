<template>
  <div class="row">
    <force-revalidation></force-revalidation>
    <div class="card col-md-12">
      <div class="card-header">
        <h5>
          <i class="nc-icon nc-badge"></i>
          {{ $t('accountsforbroker') }}
        </h5>
        <hr>
      </div>
      <div class="card-body">

        <!-- In progress -->
        <div v-if="inprogress" class="text-center">
          <h2>{{ $t('pleasewait') }}</h2>
          <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
        </div>

        <div v-if="inprogress === false" class="row">

          <div class="col-sm-2">
            <label>{{ $t('searchuser') }}</label>
            <fg-input
              class="input-sm"
              :placeholder="searchtxt"
              v-model="searchuser"
              v-on:change="loadList"
              addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>

          <div class="col-md-2">
            <label>{{ $t('filterbyelement') }}</label>
            <select class="form-control"
                    v-model="filterby.element"
                    v-on:change="this.loadList">
              <option
                v-for="item in filterby.elements"
                :key="item.prop" :value="item.prop">
                {{ item.label }}
              </option>
            </select>
          </div>

          <div class="col-md-2">
            <label>{{ $t('orderbytxt') }}</label>
            <select class="form-control"
                    v-model="sortby.column"
                    v-on:change="this.loadList">
              <option
                v-for="item in sortby.columns"
                :key="item.prop"
                :value="item.prop">
                {{ item.label }}
              </option>
            </select>
          </div>

          <div class="col-md-2">
            <label>{{ $t('ordertxt') }}</label>
            <select class="form-control"
                    v-model="sortby.order"
                    v-on:change="this.loadList">
              <option
                v-for="item in sortby.orders"
                :key="item.prop" :label="item.label" :value="item.prop">
                {{ item.label }}
              </option>
            </select>
          </div>

          <div class="col-md-2">
            <label>{{ $t('rowstxt') }}</label>
            <select class="form-control"
                    v-model="pagination.perPage"
                    v-on:change="this.loadList">
              <option
                v-for="item in pagination.perPageOptions"
                :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </div>

          <div class="col-sm-2">
            <vue-excel-xlsx
              v-if="!loading"
              style="top: +15px;position: relative;"
              class="btn btn-info pull-right"
              :data="exportdata"
              :columns="exportcols"
              :filename="exportfilename"
              :sheetname="exportsheetname">
              <i class="fa fa-download"></i>
              {{ $t('exportexcel') }}
            </vue-excel-xlsx>
          </div>

        </div>

        <el-table class="table-striped"
                  v-if="inprogress === false"
                  :data="accounts"
                  :emptyText="emptyrow"
                  border
                  style="width: 100%">

          <el-table-column
            :label="txt.account"
            width="250px"
          >
            <template slot-scope="props">


              <el-tooltip :content="$t('force_revalidation')" :open-delay="100" popper-class="adjust-width" placement="top">
                <template>
                  <p-button link v-on:click="forceRevalidationComponent(props.row)" >
                    <i class="fa fa-gear" style="font-size: x-large;"></i>
                  </p-button>
                </template>
              </el-tooltip>

              <el-tooltip :content="txt.tradeopenhistory" :open-delay="100" popper-class="adjust-width" placement="top">
                <a :href="`/accounts/historical/${props.row.id}`">
                  <p-button type="success" link >
                    <i class="fa fa-info" style="font-size: x-large;"></i>
                  </p-button>
                </a>
              </el-tooltip>
              {{ props.row.account }}
              <b>({{ props.row.platform.toUpperCase() }})</b>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('lastupdate')"
            width="160"
          >
            <template slot-scope="props">
              <span>
                {{ props.row.last_update }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="txt.fullname"
            show-overflow-tooltip
          >
            <template slot-scope="props">
              {{ props.row.full_name }}
            </template>
          </el-table-column>

          <el-table-column
            align="right"
            :label="txt.country_lang">
            <template slot-scope="props">
              {{ getCountryName(props.row.country) }}
              (<img :src="`/static/img/flags/${getFlag(props.row.lang)}.png`" alt="flag" class="dropdown-toggle">)
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            :label="txt.date">
            <template slot-scope="props">
              <span>{{ props.row.date }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="right"
            :label="txt.balance">
            <template slot-scope="props">
              {{ parseFloat(props.row.balance || 0).toFixed(2) }}
            </template>
          </el-table-column>

          <el-table-column
            align="right"
            :label="txt.equity">
            <template slot-scope="props">
              {{ parseFloat(props.row.equity || 0).toFixed(2) }}
            </template>
          </el-table-column>

          <el-table-column
            align="right"
            :label="txt.billable_profit">
            <template slot-scope="props">
              <span v-if="props.row.strat_name && !props.row.date_master">
                {{ parseFloat(props.row.billable_profit || 0).toFixed(2) }}
              </span>
              <span v-else>
                -
              </span>
            </template>
          </el-table-column>

          <el-table-column
            :label="txt.strat_name"
            show-overflow-tooltip
          >
            <template slot-scope="props">
              <i class="fa fa-star text-primary"
                 title="Master"
                 v-if="props.row.date_master"></i>
              <span v-if="props.row.strat_name">
                {{ props.row.strat_name }}
              </span>
              <span v-else>
                -
              </span>
            </template>
          </el-table-column>


        </el-table>

      </div>
      <div class="card-footer">
        <hr>
        <p-pagination
          v-if="inprogress === false"
          class="pull-right"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="pagination.total">
        </p-pagination>
      </div>
    </div>
  </div>

</template>
<script>
import Vue from 'vue'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import {Table,Tooltip} from 'element-ui'
import {Card} from "@/components/UIComponents";
import VueExcelXlsx from "vue-excel-xlsx";
import constants from "@/assets/constants";
import ForceRevalidation from "@/components/Dashboard/Views/Dashboard/ChangeLogs/force-revalidation.vue";
import {mapActions, mapState} from "vuex";

const countries = {};
constants.methods.countries().forEach(contry => {
  countries[contry.code] = contry.name
})

const user_data = JSON.parse(localStorage.getItem('user-info'));

Vue.use(VueExcelXlsx)

export default {
  components: {
    ForceRevalidation,
    Card,
    PPagination,
    Table,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      broker: user_data.app_metadata.broker,
      loading: true,
      inprogress: true,
      searchuser: '',
      searchtxt: this.$t('searchtxt'),
      exportdata: [],
      exportfilename: '',
      exportsheetname: this.$t('statementtxt'),
      emptyrow: this.$t('noaccountsfound'),
      accounts: [],
      exportcols: [
        {
          label: this.$t('account'),
          field: "account"
        },
        {
          label: this.$t('date'),
          field: "date"
        },
        {
          label: this.$t('fullname'),
          field: "full_name"
        },
        {
          label: this.$t('country'),
          field: "country"
        },
        {
          label: this.$t('user_lang'),
          field: "lang"
        },
        {
          label: this.$t('balance'),
          field: "balance"
        },
        {
          label: this.$t('equity'),
          field: "equity"
        },
        {
          label: this.$t('strategytxt'),
          field: "strat_name"
        }
      ],
      txt: {
        account: this.$t('account'),
        fullname: this.$t('fullname'),
        strat_name: this.$t('strategytxt'),
        balance: this.$t('balance'),
        equity: this.$t('equity'),
        country: this.$t('country'),
        language: this.$t('user_lang'),
        tradeopenhistory: this.$t('tradeopenhistory'),
        country_lang: this.$t('country') + " (" + this.$t('user_lang') + ")",
        date: this.$t('date'),
        billable_profit: this.$t('billableprofit')
      },
      perpageTxt: this.$t('perpage'),
      sortbyTxt: this.$t('sortby'),
      sortbyOrder: this.$t('sortbyorder'),
      filterby: {
        element: '',
        elements: [
          {
            prop: '',
            label: this.$t('alltxt')
          },
          {
            prop: 'fol',
            label: this.$t('connected')
          },
          {
            prop: 'nonfol',
            label: this.$t('unassigned')
          }
        ]
      },
      sortby: {
        column: 'equity',
        order: 'desc',
        orders: [
          {
            prop: 'asc',
            label: this.$t('ascending')
          },
          {
            prop: 'desc',
            label: this.$t('descending')
          }
        ],
        columns: [
          {
            prop: 'account',
            label: this.$t('account')
          },
          {
            prop: 'date',
            label: this.$t('date')
          },
          {
            prop: 'full_name',
            label: this.$t('fullname')
          },
          {
            prop: 'balance',
            label: this.$t('balance')
          },
          {
            prop: 'country',
            label: this.$t('country')
          },
          {
            prop: 'user_lang',
            label: this.$t('user_lang')
          },
          {
            prop: 'equity',
            label: this.$t('equity')
          },
          {
            prop: 'strat_name',
            label: this.$t('strategytxt')
          }
        ]
      },
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25,50],
        total: 0
      },
    }
  },
  methods: {
    ...mapActions('forceRevalidation', ['openForceRevalidationModal','setAccount']),
    getFlag(lang) {
      if (lang) {
        if (lang.toUpperCase() === 'EN') {
          return 'GB';
        }
        if (lang.toUpperCase() === 'AR') {
          return 'AE';
        }
        return lang.toUpperCase()
      }
      return 'GB'
    },
    getCountryName(sigla) {
      return countries[sigla]
    },
    getPaginate() {
      return this.pagination
    },
    getFilter() {
      return {
        user: this.searchuser,
        connect: this.filterby.element,
      }
    },
    getOrder() {
      return {
        orderBy: this.sortby.column,
        order: this.sortby.order,
      }
    },
    loadList() {
      this.loading = true;
      this.inprogress = true;
      this.$listBrokerAccounts({
        broker: this.broker,
        paginate: this.getPaginate(),
        filter: this.getFilter(),
        order: this.getOrder()
      }).then(this.successHandler, this.errorHandler)

      this.$listBrokerAccounts({
        broker: this.broker,
        filter: this.getFilter(),
        order: this.getOrder()
      }).then(this.successExportHandler, this.errorExportHandler)
    },
    successHandler(resp) {
      if (!resp.success) {
        return
      }
      this.accounts = resp.data.accounts;
      this.pagination.total = resp.data.count;
      const now = this.$moment('timezone', 'Europe/London', 'LLLL ss').format("YYYY-MM-DD");
      this.exportfilename = this.$t('accountstxt') + "-" + now;
      this.inprogress = false;
    },
    errorHandler() {
    },
    successExportHandler(resp) {
      if (resp.success) {
        this.exportdata = resp.data.accounts;
        this.loading = false;
        this.inprogress = false;
      }
    },
    errorExportHandler() {
      this.exportdata = [];
      this.loading = false;
      this.inprogress = false;
    },
    forceRevalidationComponent(row) {
      let success = resp => {
        if (!resp.data.can_revalidate) {
          this.$toast.warning(this.$t('already_in_revalidation_queue'))
        } else {
          this.setAccount({
            accountNumber: row.account,
            password: row.password,
            type: row.type == null ? 'follower' : 'master',
            platform: row.platform,
            server: row.server,
            userid: row.userid,
            symbol: row.symbol
          })
          this.openForceRevalidationModal()
        }
      }
      let failOp = resp => {
        this.$toast.error(this.$('unkownerror'));
      }
      this.$canRevalidate(row.account).then(success, failOp)
    },
  },
  mounted() {
    this.loadList()
  },
  watch: {
    "pagination.currentPage": function () {
      this.loadList()
    }
  },
  created() {
    this.$setTranslatingInProgress();
  },
  beforeDestroy(){
    this.$root.$off("showTranslatingProgress");
  }
}
</script>

<style>
</style>
